import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import LandingBanner from '../components/LandingBanner/LandingBanner';

const ThankYouPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <LandingBanner title="Thank you!" alt />
      <section className="internal-page__section">
        <p>
          Thank you we have received your submission.{' '}
          <Link to="/" className="ww-link">
            Back to home.
          </Link>
        </p>
      </section>
    </Layout>
  );
};

export default ThankYouPage;
